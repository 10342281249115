/*----------------------------------------------------------------------------
 *
 * TYPOGRAPHY
 *
 *---------------------------------------------------------------------------*/

html {
  font-size: 100%;
  font-style: normal;
  font-weight: 400;
  text-size-adjust: none;

  /* Fluid type size from 16px to 20px between --bp-min and --bp-max breakpoints */

  @media (--bp-min) {
    & {
      font-size: 1em; /* 16px at 320px */
      font-size: calc(1em + (20 - 16) * ((100vw - 320px)/(1320 - 320)));
    }
  }

  @media (--bp-max) {
    & {
      font-size: calc(20em/16); /* 20px at 1320px */
    }
  }
}

body {
  line-height: 1.45;
  color: var(--text);
  font-family: var(--font-family-text);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
    HEADING and TEXT SIZES
*/

.hed-alpha,
.hed-beta,
.hed-gamma,
.hed-delta,
.hed-epsilon {
  font-style: normal;
  display: block;
  line-height: 1.1;
  hyphens: none;
}

.hed-alpha {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--alpha);
}

.hed-beta {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--beta);
}

.hed-gamma {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--gamma);
}

.text-delta {
  font-size: var(--delta);
  font-weight: bold;
}

.text-epsilon {
  font-size: var(--epsilon);
  font-weight: bold;
}

.text-zeta {
  font-size: var(--zeta);
  font-weight: normal;
}

.text-eta {
  font-size: var(--eta);
  font-weight: normal;
}

p {
  margin: .75rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: var(--accent-aa);
  text-decoration-color: var(--accent);
  &:link,
  &:visited {
    color: var(--accent-aa);
    text-decoration-color: var(--accent);
  }
  &:hover,
  &:active,
  &:focus {
   color: var(--accent);
   text-decoration-color: var(--accent);
  }
}

ul,
ol {
  margin: .75rem 0;
}

ul li,
ol li {
  margin-top: .5rem;
}

/* Helpers */

.h-nobr {
  white-space: nowrap;
}

.h-break-word {
  word-wrap: break-word;
}

.h-flush-top {
  margin-top: 0;
}

.h-flush-left {
  margin-left: 0;
  text-align: left;
}

.h-flush-right {
  margin-right: 0;
  text-align: right;
}
