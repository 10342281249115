/*----------------------------------------------------------------------------
 *
 * Core: SIZES
 *
 *---------------------------------------------------------------------------*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Modular Type Sizes */

:root {

  --rem:     100%;     /* Eg. All type, margin, and padding sizes are factors of
                          the default type size of the browser, the "rem",
                          typically 16px. */

  --base:         1rem;     /* Eg. Body font */
  --alpha:        2.3635rem;   /* Eg. H1ish Headings */
  --beta:         1.784rem; /* Eg. H2ish Headings */
  --gamma:        1.346rem;   /* Eg. H3ish Headings */
  --delta-plus:   1.25rem;     /* For use with small-caps when supported. Emulates size of delta in UPPERCASE. */
  --delta:        1rem;     /* Eg. H4ish Headings */
  --epsilon:      .9090rem; /* Eg. Button Text */
  --zeta:         .825rem; /* Eg. Fine print */
  --eta:          .75rem;   /* Eg. Really fine print */

  --line-height: 1.35;
  --leading: calc(1rem * var(--line-height));

}

/* Content Area Related Sizes */

:root {
  --measure: 40rem;
  --content-width: 48rem;
}

/* Viewport Width Variables */

:root {

  --bp-min:          320px;
  --bp-min-x:        375px;
  --bp-min-xx:       425px;
  --bp-min-xxx:      500px;
  --bp-mid:          768px;
  --bp-mega:         1024px;
  --bp-max:          1320px

}

/* Custom Media Declarations for Breakpoints */

@custom-media --bp-min (min-width: 320px);
@custom-media --bp-min-x (min-width: 375px);
@custom-media --bp-min-xx (min-width: 425px);
@custom-media --bp-min-xxx (min-width: 525px);
@custom-media --bp-mid (min-width: 768px);
@custom-media --bp-mega (min-width: 1024px);
@custom-media --bp-max (min-width: 1320px);
