@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.c-autocomplete {
  position: relative;
  width: 100%;
}

.c-autocomplete_options {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin: 0;
  width: 100%;
  max-height: 20em;
  border: 1px solid lightgrey;
  border-top: none;
  overflow-y: auto;
  z-index: 1;

  &[hidden], &:empty {
    display: none;
  }

  & > li {
    position: relative;
    display: block;
    padding: 0.75em 1.25em;
    margin: 0;
    margin-bottom: -1px;
    background-color: white;
    border-top: 1px solid lightgrey;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      z-index: 2;
      color: white;
      background-color: dodgerblue;
      border-color: dodgerblue;
    }

    &[aria-disabled] {
      color: gray;
    }

    &[role="status"] {
      text-align: center;
    }
  }
}

.c-autocomplete__throbber {
  border: 5px solid var(--accent);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: spin 1s linear infinite;
}
