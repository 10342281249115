/*----------------------------------------------------------------------------
 *
 * Core: COLOR
 *
 *---------------------------------------------------------------------------*/

:root {

  /* Colors with "-our-" in their name are sourced from the OUR brand guidelines. Pulled from Bookworms web. */

  --our-primary-orange:       rgb(252,76,2);    /* #FC4C02 */
  --our-globalnav-orange:     rgb(241,89,42);   /* #F1592A  - a less bright, WCAG AA+ compliant orange */
  --aa-orange:                rgb(215,65,2);    /* #d74102 - AA orange with white — used in the CMS, skews reddish */
  --our-primary-yellow:       rgb(255,184,28);  /* #FFB81C */
  --our-primary-gray:         rgb(110,98,89);   /* #6E6259 */
  --our-primary-dark-gray:    rgb(69,53,54);    /* #453536 */

  /* Neutral and accent color variables */

  --black:          #000000;
  --text:           var(--our-primary-dark-gray);
  --dark-gray:      #4c4c4c; /* 70% black */
  --aa-gray:        #767676; /* The lightest gray that is accessible at < 18px text on white */
  --aa-large-gray:  #959595; /* The lightest gray that is accessible at > 18px text on white */
  --med-gray:       #bfbfbf; /* 25% black */
  --light-gray:     #ebebeb; /* 8% black */
  --white:          #ffffff;

  --accent:       var(--our-globalnav-orange);
  --accent-aa:    var(--aa-orange);

  --highlight:         rgba(255,184,28,.35); /* Translucent OUR primary yellow */
}

/*

Some of the components in the Reg App are borrowed from Milligram
https://milligram.github.io. Milligram uses the following colors, they will
need to be replaced with colors from the Reg App Palette:

color-initial: #fff !default
color-primary: #9b4dca !default
color-secondary: #606c76 !default
color-tertiary: #f4f5f6 !default
color-quaternary: #d1d1d1 !default
color-quinary: #e1e1e1 !default

*/

.c-color-swatch {
  display: block;
  height: 3rem;
}

.c-color-swatch::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}

.color-our-primary-orange::before {
  background-color: var(--our-primary-orange);
}

.color-our-globalnav-orange::before,
.color-accent::before {
  background-color: var(--accent);
}

.color-aa-orange::before,
.color-accent-aa::before {
  background-color: var(--accent-aa);
}

.color-our-primary-yellow::before {
  background-color: var(--our-primary-yellow);
}

.color-our-primary-gray::before {
  background-color: var(--our-primary-gray);
}

.color-our-primary-dark-gray::before,
.color-text::before {
  background-color: var(--text);
}

.color-dark-gray::before {
  background-color: var(--dark-gray);
}

.color-aa-gray::before {
  background-color: var(--aa-gray);
}

.color-med-gray::before {
  background-color: var(--med-gray);
}

.color-light-gray::before {
  background-color: var(--light-gray);
}
