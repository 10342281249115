/* ----------------------------------------------------------------------------
 *
 * Core: ICONS
 *
 * ------------------------------------------------------------------------- */

.c-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.c-icon-name {
  font-size: 32px;
  color: red;
}
========================================== */
