/*
  Rails uses a helper called `button_to` to generate Javascript-free "links" to
  non-GET application routes. While this is helpful for a number of reasons, it
  also leaves us with the mess of making sure that `<button>`s look simpler.

  We could scope this to a particular parent element, but we aren't using
  `<button>`s outside this context, and the extra qualifications would change
  the rule's specificity.
*/
button {
  background: unset;
  padding: unset;
  border: unset;
  cursor: pointer;
  line-height: 1;
}
