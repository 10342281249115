/*----------------------------------------------------------------------------
 *
 * PAGES AND LAYOUTS
 *
 *---------------------------------------------------------------------------*/

body {
  background: var(--white);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.l-info-wrapper {
  margin: 4rem auto 5rem;
  padding: 0 1rem 0;
  width: 100%;
  max-width: 32rem;
}

.l-regapp-wrapper {
  background: var(--accent);
  background:
    url('forms/texture-regform-white15.svg'),
    linear-gradient(var(--accent) 0%, var(--accent) 18.75rem, var(--white) 18.75rem,  var(--white) 100%);
  min-height: 20rem;
  background-size:
    67rem,
    100%;
  background-repeat:
    repeat-x,
    no-repeat;
  background-blend-mode:
    overlay,
    normal;
}

.l-regapp-wrapper_pending {
  background: var(--our-primary-yellow);
  background-image:
    url('forms/texture-regform-white15.svg'),
    linear-gradient(var(--our-primary-yellow) 0%, var(--our-primary-yellow) 18.75rem, var(--white) 18.75rem,  var(--white) 100%);
    background-size:
      67rem,
      100%;
    background-repeat:
      repeat-x,
      no-repeat;
}

.l-regapp-wrapper_denied {
  background: var(--text);
  background-image:
    url('forms/texture-regform-white15.svg'),
    linear-gradient(var(--text) 0%, var(--text) 18.75rem, var(--white) 18.75rem,  var(--white) 100%);
    background-size:
      67rem,
      100%;
    background-repeat:
      repeat-x,
      no-repeat;
}
