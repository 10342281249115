/* ----------------------------------------------------------------------------
 *
 * Component: Reg App Form
 * Contains/constrains/centers the content for all registration app screens
 * except info pages.
 *
 * ------------------------------------------------------------------------- */

.c-regapp {
  margin: auto auto 5rem;
  padding: 2.625rem 1rem 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 42.8rem;
}

.c-regapp * {
  box-sizing: border-box;
}

/*
  -- Reg app hed
  Presumably an H1. Appears over the textured
  motif that signifies the Registration App screens.
*/

.c-regapp__hed {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--alpha);
  margin: 0 auto 2.625rem;
  line-height: 2.75rem;
  text-align: center;
  color: var(--white);
}

/*
  -- Reg app inner
  Inner container for Registration App fields and options.
  Appears as a “floating” white box. Applied to a fieldset element, when a
  ieldset is appropriate. Otherwise, appliied to a div.
*/

.c-regapp__inner {
  margin: 2.5rem auto;
  background-color: var(--white);
  padding: 1rem 1.5rem 2.75rem;
  box-shadow: .25rem .3rem .5rem 0 rgba(0, 0, 0, 0.15);
  position: relative;
}

/*
  -- Ripcord
  Class for the “back” (or what-have-you) link that appears in the upper-left
  corner of .c-regapp__inner.
*/

.c-regapp__ripcord {
  font-size: var(--epsilon);
  line-height: 1.5rem;
}

/*
  -- Inner badge
  Iconography for school/district/other
*/

img.c-regapp__inner-badge {
  width: 10rem;
  display: block;
  height: auto;
  margin: .5rem auto;
}

.c-regapp__ripcord + img.c-regapp__inner-badge {
  margin-top: -1.75rem;
}

/*
  -- Legend
  Legends are annoying to style, but semantically worth it, imo,
  if they make sense for the context, eg. we need a heading/subheading to appear
  first in, or to label, a fieldset.
*/

.c-regapp__legend {
  margin: 1.25rem auto 1rem;
  padding: 0;
  font-size: var(--gamma);
  font-family: var(--font-family-display);
  font-weight: 700;
  line-height: 1.2;
  float: left; /* Tricksy! Setting the legend to float positions the legend inside of the fieldset. */
  position: relative; /* This and the following two rules are required to center an element that would otherwise be aligned left due to the float. */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

/*
  -- Subhed
  Presumably an H2. Intentionally has the same appearance as
  the legend above. Use if a legend element doesn't make sense (eg. to style a
  subheading not contained within a fieldset, or to style a subheading that is
  not the first/only heading in a fieldset).
*/

.c-regapp__subhed {
  font-family: var(--font-family-display);
  font-weight: 700;
  font-size: var(--gamma);
  color: var(--text);
  line-height: 1.2;
}

/*
  -- Field and info groups
  Fieldgroups appear in forms, infogroups do not, but they’re laid out
  identically within the Registration App.
*/

.c-regapp__fieldgroup,
.c-regapp__infogroup {
  margin: 1rem auto;
  width: 100%;
  max-width: 23.5rem;
  clear: left; /* I know! A clearing floats flashback! Clears the floated legend element when it’s present. */
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 1.25rem;
  }
}

/*
  -- Button group
  Layout for groups of buttons.
*/

.c-regapp__buttongroup {
  margin: 1.5rem auto;
  width: 100%;
  max-width: 23.5rem;
  text-align: center;
  clear: left; /* I know! A clearing floats flashback! Clears the floated legend element when it’s present. */
  &:last-child {
    margin-bottom: 0;
  }
}

.c-regapp__buttongroup--sso {
  position: relative;
  padding-top: 2rem;
  margin-top: 2rem;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    background-color: var(--med-gray);
    height: 1px;
  }

  &::after {
    content: 'or';
    font-variant: small-caps;
    letter-spacing: 0.05em;
    position: absolute;
    top: -0.875em;
    left: 50%;
    margin-left: -1rem;
    width: 2rem;
    background-color: var(--white);
    color: var(--aa-gray);
  }
}

/*
  -- Big radio group
  Layout for grouping `c-regapp__bigradioduo` elements
*/

.c-regapp__bigradiogroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 30.5rem;
  padding-top: .5rem;
  margin: auto;
  text-align: center;
  clear: left;
}

/*
  -- Subject groups
  Styles for subject groupings of curriculum options.
*/

.c-regapp__subjectgroup {
  margin: 2rem auto 1rem;
  border: .125rem solid var(--our-primary-yellow);
  border-radius: .4rem;
  padding: .5rem 1rem;
  max-width: 30.5rem;
  position: relative;
}

.c-regapp__subjectlabel {
  position: absolute;
  left: 1.25rem;
  top: -.575em;
  margin: 0;
  padding: 0 .25rem;
  line-height: 1.1rem;
  background: var(--white);
  text-transform: uppercase;
  font-size: var(--delta);
  letter-spacing: .03em;
}

/*
  -- Label-related patterns
  Styles for label elements and the various types of text that
  they might contain.
*/

.c-regapp__labelgroup {
  display: block;
  margin-bottom: .5rem;
  line-height: 1.25;
}

.c-regapp__labeldesc {
  padding-right: .75em;
}

.c-regapp__labelhelp {
  display: inline-block;
  color: var(--aa-gray);
  font-size: var(--zeta);
  font-weight: 400;
  line-height: 1.2;
}

.c-regapp__labelerror {
  display: block;
  color: var(--accent-aa);
  font-size: var(--zeta);
  font-weight: 700;
  line-height: 1.2;
}

/*
  -- Big radio-related patterns
*/

.c-regapp__bigradioduo {
  display: inline-block;
  width: 100%;
  flex: 1 1 9rem;
  max-width: 9rem;
  margin: 0 .5rem;
  overflow: hidden;
  position: relative;
  clear: left; /* I know! A clearing floats flashback! Clears the floated legend element when it’s present. */
}

.c-regapp__bigradioduo input[type="radio"] {
  position: absolute;
  left: -100%;
}

.c-regapp__bigradio {
  cursor: pointer;
  line-height: 1.2;
  margin-bottom: 1.25rem;
}

.c-regapp__bigradio::before {
  content: "";
  display: block;
  width: 100%;
  height: 5.25rem;
  margin: auto auto .5rem;
  box-sizing: border-box;
  border: var(--light-gray) .125rem solid;
  border-radius: .4rem;
  background-size: 6.5rem;
  background-position: center;
  background-repeat: no-repeat;
}

.c-regapp__bigradio.for-school::before {
  background-image: url('forms/org-icon-school.svg');
}

.c-regapp__bigradio.for-district::before {
  background-image: url('forms/org-icon-district.svg');
}

.c-regapp__bigradio.for-other::before {
  background-image: url('forms/org-icon-other.svg');
}

.c-regapp__bigradio:hover::before {
  border-color: var(--our-primary-yellow);
}

input:checked + .c-regapp__bigradio::before {
  background-color: var(--our-primary-yellow);
  border-color: var(--our-primary-yellow);
}

input:checked + .c-regapp__bigradio.for-school::before {
  background-image: url('forms/org-icon-school-selected.svg');
}

input:checked + .c-regapp__bigradio.for-district::before {
  background-image: url('forms/org-icon-district-selected.svg');
}

input:checked + .c-regapp__bigradio.for-other::before {
  background-image: url('forms/org-icon-other-selected.svg');
}


/*
  -- Reg app details
  Show/hide disclosure pattern
*/

.c-regapp__details {
  font-size: var(--zeta);
  margin: auto;
}

.c-regapp__details > summary {
  cursor: pointer;
  list-style: none;
  position: relative;
  text-decoration: underline;
  color: var(--accent-aa);
}

.c-regapp__details > summary:focus,
.c-regapp__details > summary:target {
  outline: none;
  animation: a-yellow-fade 1s ease-in-out;
}

.c-regapp__details > summary::before {
  content: "*";
  position: absolute;
  font-size: var(--gamma);
  line-height: calc(1.45 * var(--zeta));
  left: -.5em;
}

.c-regapp__details[open] summary ~ * {
  animation: a-sweep-in .5s ease-in-out; /* "a-sweep-in" animation defined below */
}

.c-regapp__details > summary::-webkit-details-marker {
  display: none;
}

/*
  -- Input Duo
  Conjoined input field + button = inputduo!
*/

.c-regapp__inputduo {
  display: flex;
}

.c-regapp__inputduo_zip { /* Keep things narrow in this known scenario */
  max-width: 10rem;
}

.c-regapp__inputduo input[type='email'],
.c-regapp__inputduo input[type='number'],
.c-regapp__inputduo input[type='password'],
.c-regapp__inputduo input[type='search'],
.c-regapp__inputduo input[type='tel'],
.c-regapp__inputduo input[type='text'],
.c-regapp__inputduo input[type='url'] {
  flex: 1 1 auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.c-regapp__inputduo .c-button,
.c-regapp__inputduo button,
.c-regapp__inputduo input[type='button'],
.c-regapp__inputduo input[type='reset'],
.c-regapp__inputduo input[type='submit'] {
  flex: 0 0 3rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
}

/*
  -- Reg app note style
  One step smaller than default text size.
*/

.c-regapp__note {
  display: inline-block;
  color: var(--text);
  font-size: var(--epsilon);
  font-weight: 400;
  line-height: 1.2;
}

.c-regapp__note_centered {
  display: block;
  text-align: center;
}

.c-regapp__note_notice {
  font-size: var(--zeta);
  font-weight: 700;
  line-height: 1.2;
}

.c-regapp__note_alert {
  color: var(--accent-aa);
  font-size: var(--zeta);
  font-weight: 700;
  line-height: 1.2;
}

/*
  -- Curriculum Choice
  Conjoined Text + "Go" CTA
*/

.c-regapp__curriculum-choice {
  border-color: var(--our-primary-yellow);
  background-color: var(--light-gray);
  border-radius: .4rem;
  color: var(--text);
  display: block;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
  @media (--bp-min-xxx) {
    & {
      align-items: baseline;;
    }
  }
}

.c-regapp__curriculum-choice:hover {
  color: var(--accent-aa);
}

.c-regapp__curriculum-choice::after {
  content: "";
  width: 0;
  height: 0;
  border-top: transparent solid .5rem;
  border-right: transparent solid .75rem;
  border-bottom: transparent solid .5rem;
  border-left: var(--text) solid .75rem;
  margin-left: auto;
  align-self: center;
}

.c-regapp__curriculum-choice:hover::after {
  border-left-color: var(--accent);
}

.c-regapp__curriculum-title {
  padding: .75rem 0;
  margin-right: .75em;
  flex: 0 1 auto;
  line-height: 1.3rem;
  @media (--bp-min-xxx) {
    & {
      white-space: nowrap;
      flex: 0 0 0;
    }
  }
}

.c-regapp__curriculum-edition {
  font-size: var(--zeta);
  text-transform: uppercase;
  letter-spacing: .02em;
  font-weight: 700;
  padding: 0 1rem 0 .5rem;
  line-height: 1.3rem;
  color: var(--accent-aa);
  white-space: nowrap;
  display: inline-block;
  vertical-align: baseline;
}

.c-regapp__edition-separator {
  color: var(--our-primary-yellow);
  border-style: dashed;
}

/*
  -- Reg App Helper Classes
    For aligning elements outside `.c-regapp-*group`s with the group
    to which they refer.
*/

.c-regapp .for-fieldgroup,
.c-regapp .for-infogroup,
.c-regapp .for-buttongroup {
  max-width: 23.5rem;
  margin-left: auto;
  margin-right: auto;
}

.c-regapp .for-bigradiogroup {
  max-width: 30.5rem;
  padding: 0 .75rem;
  margin-left: auto;
  margin-right: auto;
}

.c-regapp .for-subjectgroup {
  max-width: 30.5rem;
  margin-left: auto;
  margin-right: auto;
}

.c-regapp__ref-marker { /* For less obtrusive linked footnote markers */
  text-decoration: none;
  line-height: 0;
  font-size: 1.3em;
}

/*
  -- ANIMATION
*/

@keyframes a-sweep-in {
  0% {
    opacity: 0;
    margin-left: .5rem;
    margin-right: -.5rem;
  }
  100% {
    opacity: 1;
    margin-left: 0rem;
    margin-right: 0;
  }
}

@keyframes a-yellow-fade {
  0% {
    background-color: rgba(255,184,28,.25); /* Based on our-primary-yellow */
  }
  100% {
    background-color: rgba(255,184,28,0); /* Based on our-primary-yellow */
  }
}
