/*----------------------------------------------------------------------------
 *
 * Core: FONTS
 *
 *---------------------------------------------------------------------------*/

:root {
  --font-family-nav: 'Roboto', 'Trebuchet MS', Trebuchet, Tahoma, sans-serif; /* Global Nav, Global Footer, Modal Message 500,700 weight. 300 weight used for headings in Pattern library only. */
  --font-family-ui: 'Roboto', 'Trebuchet MS', Trebuchet, Tahoma, sans-serif; /* Global Nav, Global Footer, Modal Message 500,700 weight. 300 weight used for headings in Pattern library only. */
  --font-family-text: 'Source Sans Pro', sans-serif; /* Default text. 400, 600, 700 weight */
  --font-family-display: agenda-one, sans-serif; /* Headings — 600, 700 */
}
