/*========================================
=                                        =
=                Choices                 =
=     CSS Customized for OUR Reg App     =
=                                        =
=========================================*/

.choices {
  position: relative;
  margin-bottom: 1rem;
  font-size: var(--epsilon);
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: var(--light-gray);
  cursor: not-allowed;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices[data-type*='select-one'] {
  cursor: pointer;
}

.choices[data-type*='select-one'] .choices__inner {
  padding-bottom: .375rem;
}

.choices[data-type*='select-one'] .choices__input {
  display: block;
  width: 100%;
  padding: .3rem 1rem;
  border-bottom: .0625rem solid var(--aa-large-gray);
  border-radius: 0;
  border-left: none;
  border-top: none;
  border-right: none;
  background-color: var(--white);
  margin: 0;
  height: 2.5rem;
}

.choices[data-type*='select-one'] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: .4rem;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -.5rem;
  margin-right: 1.25rem;
  height: 1rem;
  width: 1rem;
  border-radius: 10em;
  opacity: 0.5;
}

.choices[data-type*='select-one'] .choices__button:hover, .choices[data-type*='select-one'] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*='select-one'] .choices__button:focus {
  box-shadow: 0 0 0 .1rem var(--light-gray);
}

.choices[data-type*='select-one'] .choices__item[data-value=''] .choices__button {
  display: none;
}

.choices[data-type*='select-one']:after {
  content: '';
  height: 0;
  width: 0;
  border-style: solid;
  border-color: var(--text) transparent transparent transparent;
  border-width: .35rem;
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: -.125rem;
  pointer-events: none;
}

.choices[data-type*='select-one'].is-open:after {
  border-color: transparent transparent var(--text) transparent;
  margin-top: -.5rem;
}

.choices[data-type*='select-one'][dir='rtl']:after {
  left: .575rem;
  right: auto;
}

.choices[data-type*='select-one'][dir='rtl'] .choices__button {
  right: auto;
  left: 0;
  margin-left: 1.25rem;
  margin-right: 0;
}

.choices[data-type*='select-multiple'] .choices__inner,
.choices[data-type*='text'] .choices__inner {
  cursor: text;
}

.choices[data-type*='select-multiple'] .choices__button,
.choices[data-type*='text'] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -.2rem;
  margin-bottom: 0;
  margin-left: .4rem;
  padding-left: .75rem;
  border-left: .0625rem solid #008fa1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 2rem;
  width: .4rem;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}

.choices[data-type*='select-multiple'] .choices__button:hover, .choices[data-type*='select-multiple'] .choices__button:focus,
.choices[data-type*='text'] .choices__button:hover,
.choices[data-type*='text'] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: var(--white);
  padding: .625rem .5rem .1875rem 0;
  border: .0625rem solid var(--aa-large-gray);
  border-radius: .4rem;
  font-size: var(--epsilon);
  min-height: 3rem;
  overflow: hidden;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: var(--aa-large-gray);
}

.is-open .choices__inner {
  border-radius: .4rem .4rem 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 .4rem .4rem;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: .2rem .75rem .2rem 1rem;
  width: 100%;
}

[dir='rtl'] .choices__list--single {
  padding-right: .2rem;
  padding-left: 1rem;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 1rem;
  padding: .2rem 1rem;
  font-size: .6rem;
  font-weight: 500;
  margin-right: .1875rem;
  margin-bottom: .1875rem;
  background-color: var(--light-gray);
  border: .0625rem solid var(--our-primary-yellow);
  color: var(--white);
  word-break: break-all;
  box-sizing: border-box;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: .25rem;
}

[dir='rtl'] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: .1875rem;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: var(--our-primary-yellow);
  border: .0625rem solid #008fa1;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: .0625rem solid #919191;
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: var(--white);
  border: .0625rem solid var(--aa-large-gray);
  top: 100%;
  margin-top: -.0625rem;
  border-bottom-left-radius: .4rem;
  border-bottom-right-radius: .4rem;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}

.choices__list--dropdown.is-active {
  visibility: visible;
}

.is-open .choices__list--dropdown {
  border-color: var(--aa-large-gray);
}

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -.0625rem;
  border-radius: .4rem .4rem 0 0;
}

.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 15rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.choices__list--dropdown .choices__item {
  position: relative;
  padding: .4rem 1rem;
  font-size: var(--epsilon);
}

.choices__list--dropdown .choices__item:nth-of-type(even) {
  /* background: var(--light-gray); */
}

[dir='rtl'] .choices__list--dropdown .choices__item {
  text-align: right;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 5rem;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: var(--zeta);
    opacity: 0;
    position: absolute;
    right: .5rem;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir='rtl'] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 5rem;
    padding-right: .5rem;
  }
  [dir='rtl'] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: .5rem;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: var(--highlight);
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: .75;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: .6rem;
  padding: .5rem;
  border-bottom: .0625rem solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: .7rem;
  margin-bottom: .25rem;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: .3rem 0 .3rem 1rem;
}

.choices__input:focus {
  outline: 0;
}

[dir='rtl'] .choices__input {
  padding-right: .1rem;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 1;
  color: var(--aa-gray);
}

.choices__list--dropdown .choices__placeholder {
  cursor: default;
  opacity: 0.5;
}

.choices__list--dropdown .choices__placeholder::after,
.choices__list--dropdown .choices__placeholder.is-highlighted::after {
  opacity: 0;
}

/*=====  End of Choices  ======*/
