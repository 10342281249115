/* ----------------------------------------------------------------------------
 *
 * Component: HubSpot Form Styles
 *
 * Overriding styles for Hub Spot inline/embedded form markup and classes.
 *
 * ------------------------------------------------------------------------- */

.hs-form {

}

form.hs-form fieldset {
  margin-top: 1rem;
}

/* Style this heading like .c-regapp__subhed */

.hs-form h2 {
  font-family: var(--font-family-display);
  font-size: var(--gamma);
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0;
}

/* HS-specific number input-related styles */

.hs-fieldtype-number {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row-reverse;
}

.hs-fieldtype-number label {
  flex: 1 0 10rem;
  margin: .5rem 0 .5rem 0;
  line-height: 1.1;
}

.hs-fieldtype-number label em {
  font-weight: normal;
  font-style: normal;
}

.hs-fieldtype-number label em::before {
  content: "— ";
}

.hs-field-desc {
  font-weight: normal;
  font-size: var(--eta);
}

form.hs-form fieldset .hs-input[type="number"] {
  flex: 1 1 5rem;
  width: 5rem;
  padding-right: .25rem;
  height: 2rem;
}

/* HS-specific checkbox input-related styles */

.hs-dependent-field .hs-fieldtype-booleancheckbox .hs-form-booleancheckbox label {
  font-size: var(--delta);
}

.hs-input[type="checkbox"] {
  margin-right: .25em;
}

.hs-fieldtype-checkbox + .hs-fieldtype-checkbox {
  margin-top: 1.5em;
}

.hs-fieldtype-checkbox label em {
  font-weight: normal;
  font-style: normal;
}

.hs-fieldtype-checkbox label em::before {
  content: "— ";
}

.hs-form-checkbox-display {
  font-weight: normal;
  line-height: 1.2;
  font-size: var(--zeta);
}

/* HS-specific date input-related styles */

form.hs-form fieldset .hs-input[type="date"] {
  width: auto;
}

/* HS-specific note styles */

.hs-form .c-regapp__note {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Inputs List — as for radio, checkboxes, etc. */

.inputs-list {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

/* Radio-related Input Styles */

.hs-form-radio {
  list-style: none;
}

.hs-form-radio-display {
  display: flex;
  align-items: center;
}

.hs-form-radio-display span {
  font-weight: normal;
}

form.hs-form fieldset .hs-input[type="radio"] {
  margin-right: .5rem;
}

/* HS Button Styles */

.hs-button { /* Style this element like a standard reg app button */
  background-color: var(--accent-aa);
  border: 0.125rem solid var(--accent-aa);
  border-radius: .4rem;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-size: var(--eta);
  font-weight: 700;
  box-sizing: border-box;
  min-height: 2.5rem;
  letter-spacing: .04rem;
  line-height: 1rem;
  padding: .75rem 2.5rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  max-width: 100%;
  white-space: normal;
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.hs-button[type="submit"] {
  margin-top: 1rem;
}

.hs-button:focus,
.hs-button:hover {
  background-color: var(--accent);
  border-color: var(--accent);
  color: var(--white);
  outline: 0;
}

.hs-button[disabled] {
  cursor: default;
  opacity: .5;
}

.hs-button[disabled]:focus,
.hs-button[disabled]:hover {
  color: var(--white);
  background-color: var(--accent-aa);
  border-color: var(--accent-aa);
}

/* Captcha and error styles */

.hs-recaptcha {
  margin: 1rem 0;
}

.hs-error-msgs { /* List of error messages */
  margin-top: 0;
  display: inline-block;
  color: var(--accent-aa);
  font-weight: 700;
  margin-right: .5rem;
}

.hs-error-msg { /* Individual error messages */
  list-style: none;
  font-size: var(--eta);
  line-height: 1.2;
}
